<template>
    <b-modal
    id="modalChangeType"
    title="Изменить тип"
    size="sm"
    hide-footer no-enforce-focus
    :no-close-on-backdrop="true"
    @show="getTypePhoto()"
    @close="delTypePhoto()"
    @shown="getcline()"
    >
    <b-row>
        <b-col>
            <label  for="">Тип: <span class="required">*</span></label>
            <v-select
            v-model="type_photo_control_id"
            placeholder="Тип фотоконтроля"
            label="description"
            :reduce="options => options.id"
            :options="typrrge" 
            class="select-size-md"
            >
            <template v-slot:no-options>
                    <span class="no-data-text">Нет доступных файла</span>
            </template>
            </v-select>
        </b-col>
    </b-row>
    <b-row class="mt-2 mb-1">
        <b-col class="d-flex justify-content-end">
            <b-button
            @click="addTypePhoto()"
            :disabled="submitButtonDisabled"
            variant="primary">Изменить</b-button>
            <b-button class="ml-1 mr-1" @click="delTypePhoto()" variant="danger">Отмена</b-button>
        </b-col>
    </b-row>
    </b-modal>
</template>

<script>
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
    props:['data'],
    components:{
        vSelect,
    },
    data() {
        return {
            performer_id: '',
            type_photo_control_id: '',
            performer_assign_p_c_id: '',
            typrrge: [],
            bodyTypePhoto: [],
            submitButtonDisabled: false
        }
    },
    methods: {
        getcline() {
            this.performer_id = this.data.performer_id
            this.type_photo_control_id = this.data.type_photo_control_id
            this.performer_assign_p_c_id = this.data.performer_assign_p_c_id
        },
        getTypePhoto() {
        this.$http
            .get("photo-control/type")
            .then(res => {
              this.typrrge = res.data
            }).catch((err) => {
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title:"Ошибка!",
                        icon: 'XIcon',
                        variant: 'danger',
                        text: "Ошибка!",
                    },
                })
            })
          },
          addTypePhoto() {
            this.bodyTypePhoto = {
                performer_id: this.performer_id,
                type_photo_control_id: this.type_photo_control_id,
                performer_assign_p_c_id: this.performer_assign_p_c_id,
            }
            this.submitButtonDisabled = true
            this.$http
            .post(`photo-control/performer/${this.data.performer_id}/image-replace`, this.bodyTypePhoto)
            .then(() => {
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: 'Успех!',
                        icon: 'CheckIcon',
                        variant: 'success',
                        text: err.response.data.errors,
                    }
                })
                this.delTypePhoto()
                this.$emit('getphoto')
            })
            .catch((err) => {
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: 'Ошибка!',
                        icon: 'XIcon',
                        variant: 'danger',
                        text: err.response.data.errors,
                    },
                })
            })
            .finally(() => {
                this.submitButtonDisabled = false
            })
          },
          delTypePhoto() {
            this.$bvModal.hide('modalChangeType')
            this.type_photo_control_id = ''
            this.performer_assign_p_c_id = ''
            this.typrrge = []
          }
    }
}
</script>

<style scoped>
.required {
    color: red;
}
</style>