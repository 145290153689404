<template>
    <div>
      <b-row>
          <b-col cols="12">
              <b-card class="my-card m-0">
                  <b-table 
                  striped
                  small
                  id="myTables"
                  bordered
                  selectable
                  selected-variant="primary"
                  select-mode="single"
                  responsive="sm"
                  :items="photo"
                  :fields="fields"
                  @row-dblclicked="open"
                  @row-contextmenu="handleContextMenuEvent"
                  head-row-variant="secondary"
                  >
                  <template #cell(checkbox)="row">
                      <b-form-checkbox
                      v-model="row.detailsShowing"
                      plain
                      class="vs-checkbox-con"
                      @change="row.toggleDetails"
                      >
                      </b-form-checkbox>
                  </template>
                   <template #cell(url)="data"> 
                    <div>
                      <img  :src="data.item.url" style="width: 100%;" alt="">
                    </div>
                  </template> 
                  </b-table>
                  <vue-context ref="menu">
                    <li>
                      <b-link
                      class="d-flex align-items-center"
                      @click="getPhotoTreatment()"
                      v-b-modal.photo_treatment
                      >
                      <feather-icon
                          icon="EditIcon"
                          size="16"
                      />
                      <span>Редактировать</span>
                      </b-link>
                      <b-link
                      class="d-flex align-items-center"
                      @click="getChangeType()"
                      v-b-modal.change_type
                      >
                      <feather-icon
                          icon="EditIcon"
                          size="16"
                      />
                      <span>Изменить тип</span>
                      </b-link>
                      <b-link
                      class="d-flex align-items-center"
                      @click="getBlock()"
                      >
                      <feather-icon
                        icon="EditIcon"
                        size="16"
                      ></feather-icon>
                      <span>Блокировать</span>
                      </b-link>
                    </li>
                  </vue-context>
              </b-card>
          </b-col>
      </b-row>
      <modal-photo :performer_id="performer_id" :performer_assign_p_c_id="performer_assign_p_c_id" :img="img" @getphoto="getphoto"></modal-photo>
      <modal-photo-treatment :data="data" @getphoto="getphoto"></modal-photo-treatment>
      <modal-change-type :data="data" @getphoto="getphoto"></modal-change-type>
      <modal-driver-edit :driver_id="driver_id" @getphoto="getphoto"></modal-driver-edit>
    </div>
</template>

<script>
import VueContext from 'vue-context'
import ModalPhoto from "./ModalPhoto.vue";
import { resizeable } from "@/utils/TableFunctions/resizeable.js";
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue';
import modalChangeType from '@/views/component/Modal/ModalPhotocontrol/modalChangeType.vue';
import ToastificationContent from "@/@core/components/toastification/ToastificationContent.vue";
import modalPhotoTreatment from '@/views/component/Modal/ModalPhotocontrol/modalPhotoTreatment.vue';
import modalDriverEdit from '@/views/component/Modal/ModalDriver/modalDriverEdit.vue';
export default {
  components: {
    ModalPhoto,
    FeatherIcon,
    VueContext,
    modalPhotoTreatment,
    modalChangeType,
    modalDriverEdit,
  },
  data(){
      return{
          driver_id: this.$route.query.driver_id,
          showPreloader: false,
          performer_id: this.$route.query.driver_id,
          performer_assign_p_c_id: '',
          img: '',
          photo: [],
          data: [],
          fields: [
          {key: 'checkbox', label: '', thStyle: {width: '30px'}},
          {key: 'performer_assign_p_c_id', label: 'id', thStyle: {width: '30px'}},
          {key: 'start_date', label: 'Дата загрузки', sortable: true, thStyle: {width: '70px'}},
          {key: 'url', label: 'Файл', sortable: true, thStyle: {width: '100px'}},
          {key: 'type_photo_control_name', label: 'Тип', sortable: true, thStyle: {width: '150px'}},
          {key: 'comment', label: 'Комментария', sortable: true, thStyle: {width: '150px'}},
          {key: 'status_name', label: 'Статус', sortable: true, thStyle: {width: '150px'}},
          {key: 'check_user_login', label: 'Создал', sortable: true, thStyle: {width: '150px'}},
      ],
      }
  },
  mounted(){
    this.$store.commit('pageData/setdataCount', null)
    resizeable()
    this.getphoto()
  },
  methods:{
    handleContextMenuEvent(item, index, event){
      event.preventDefault();
      this.$refs.menu.open(event)
      this.data = item
       },
    getphoto() {
      this.$http
      .get(`photo-control/performer/${this.performer_id}`,) 
      .then(res =>{
        this.photo = res.data
        this.$store.commit('pageData/setdataCount', this.photo.length)
        this.$store.commit('REFRESH_DATA', false)
      }).catch(err => {
        this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: 'Ошибка!',
                        icon: 'XIcon',
                        variant: 'danger',
                        text: err.response.data.errors,
                    }
                })
      })
    },
    open(item) {
      this.performer_assign_p_c_id = item.performer_assign_p_c_id
      this.img = item.url
      setTimeout(this.openModal, 0);
    },
    getBlock() {
      setTimeout(this.bloc, 0);
    },
    getPhotoTreatment() {
      setTimeout(this.treatment, 0);
    },
    getChangeType() {
      setTimeout(this.changeType, 0);
    },
    bloc() {
      this.$bvModal.show('modalDriverEdit')
    },
    openModal() {
      this.$bvModal.show('modalPhoto')
    },
    treatment() {
      this.$bvModal.show('modalPhotoTreatment')
    },
    changeType() {
      this.$bvModal.show('modalChangeType')
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-context.scss';
.my-card .card-body{
  padding: 0px;
}
.my-card{
overflow: auto; 
height: calc(100vh - 92px);
}
@media (max-height: 700px) {
.my-card{
  overflow: auto; 
  height: 530px;
  }
}
table{
  outline: none; 
  table-layout: fixed; 
  -webkit-user-select: none;
  overflow: hidden;
  position: relative;
}
tr, td, th{
  padding: 0;
}
td, th{ 
  width: 100px; 
  white-space: nowrap; 
  overflow: hidden;  
  text-align: left; 
  box-sizing: border-box; 
  min-width: 60px;
  }
.my-danger{
  background-color: #f9cfcf;
}  
.my-success{
  background-color: #8fe2b4;
} .my-secondary{
  background-color: #dcdddf;
} .my-warning{
  background-color: #ffcd9d;
} 
</style> 